<template>
<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs-container">
        <ul class="nav nav-tabs">
          <!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
        </ul>

        <div class="tab-content">
          <div class="tab-pane active">
            <div class="panel-body">
              <fieldset :aria-timestamp="timestamp">

                <div class="form-group row"><label class="col-sm-2 col-form-label">Promotion</label>
                  <div class="col-sm-10">
                    <PromotionSelect
                      v-model="data.promotion_id"
                      name="promotion_id"
                      @input="onChangePromotion"
                      ></PromotionSelect>
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Coupon Name</label>
                  <div class="col-sm-10">
                    <LocaleEditor
                      v-model="data.coupon_name"
                      :mode="mode"
                      name="coupon_name"
                      type="text"
                      :locales="['base', 'ko', 'jp', 'hk', 'cn']"></LocaleEditor>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Coupon Description</label>
                  <div class="col-sm-10">
                    <LocaleEditor
                      v-model="data.coupon_descript"
                      :mode="mode"
                      name="coupon_descript"
                      type="editor"
                      :locales="['base', 'ko', 'jp', 'hk', 'cn']"></LocaleEditor>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <div class="form-group row"><label class="col-sm-2 col-form-label">Select User</label>
                  <div class="col-sm-10">
                    <input-checkbox
                      v-model="allChecked"
                      name="allChecked"
                      :expected="true"
                      :ripple="true">전체 선택</input-checkbox>
                    <span class="form-text"></span>
                  </div>
                </div>

                <div class="hr-line-dashed"></div>

                <template v-for="i in (1, userItems.length + 1)">
                  <div class="form-group row" ><label class="col-sm-2 col-form-label">User #{{i}}</label>

                    <div class="col-sm-10" v-if="i <= userItems.length">
                      <UserSelect
                        v-model="userItems[i-1]"
                        :key="userItems[i-1]"
                        :name="`user_id[]`"
                        :readonly="true"
                        ></UserSelect>
                    </div>

                    <div class="col-sm-10" v-if="i > userItems.length">
                      <UserSelect
                        v-model="userItems[i-1]"
                        :key="i"
                        :name="`user_id[]`"
                        :readonly="allChecked"
                        ></UserSelect>

                    </div>

                    <button v-if="i <= userItems.length" class="btn btn-danger btn-sm m-l" @click="onClickDeleteItem(userItems[i-1], i-1)">삭제</button>
                  </div>

                  <div class="hr-line-dashed"></div>
                </template>

              </fieldset>

              <div class="form-group row">
                <div class="actions">
                  <div class="left">
                    <button class="btn btn-white btn-sm" @click="onClickCancel">Cancel</button>
                  </div>

                  <div class="right">
                    <button class="btn btn-success btn-sm" :disabled="sending" @click="onClickGenerate">Generate</button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import config from "@/config"
import Editor from "@/components/utils/Editor"
import ExtraEditor from "@/components/utils/ExtraEditor"
import ResourceEditor from "@/components/utils/ResourceEditor"
import UserSelect from "@/components/utils/UserSelect"
import PromotionSelect from "@/components/utils/PromotionSelect"
import LocaleEditor from "@/components/utils/LocaleEditor"

export default {
  data() {
    return {
      mode: 'create',
      model: {},
      data: {
        promotion_id: 0
      },
      timestamp: +new Date(),
      promotion: {},
      userItems: [],
      cachedItems: [],
      allChecked: false,
      sending: false
    }
  },
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model
  },
  watch: {
    data(value, oldValue) {

    }
  },
  computed: {

  },
  methods: {
    onClickDeleteItem(item, index) {
      let userItems = this._.clone(this.userItems)
      this._.pullAt(userItems, index)
      this.$data.userItems = userItems
    },
    onClickCancel() {

    },
    onClickGenerate() {

      if (this.allChecked) {
        this.onSendAll()
        return
      }

      this.onSubmit()
    },
    onChangePromotion( promotion_id ) {

      this.$store.dispatch("promotion/get", { uid: promotion_id })
        .then((data) => {
          let oldData = this.data
          let newData = this.data

          let { extras } = data

          newData.coupon_name = extras.coupon_name || oldData.coupon_name
          newData.coupon_descript = extras.coupon_descript || oldData.coupon_descript

          this.$data.promotion = data
          this.$data.data = newData
          this.$data.timestamp = +new Date()

        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {

        })
    },
    onSendAll() {

      let params = this.data

      this.$data.sending = true
      this.$store.dispatch('coupon/generateAll', params)
        .then((data) => {
          let { total } = data
          this.$toast.success(`총 ${total}명에게 쿠폰이 발급 되었습니다. 이미 발급된 경우 재발급 되지 않습니다.`)
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    },
    onSubmit() {

      let params = this.data
      params.user_ids =  this.userItems

      this.$data.sending = true
      this.$store.dispatch('coupon/generate', params)
        .then((data) => {
          let { coupons } = data
          this.$toast.success(`${coupons.length}건이 발급 되었습니다.`)
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    }
  },
  components: {
    Editor, ExtraEditor, ResourceEditor,
    PromotionSelect, LocaleEditor, UserSelect
  }
}
</script>
